import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import {FormLabel, FormText, GAlert, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import {IProfile} from 'src/app/modules/profile/models/Profile.model'
import {emailRegExp} from 'src/app/utils/input-utils'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import {forgotPassword} from '../../services/Auth.services'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Enter your email address')
    .matches(emailRegExp, 'Wrong email format'),
})

const initialValues = {
  email: '',
}

const ForgotPassword: FC = () => {
  useWebTitle('Forgot Password')

  const [isSuccess, setIsSuccess] = useState(false)
  const [detailRes, setDetailRes] = useState<IProfile>()

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return forgotPassword(payload)
    },
    onSuccess: (res) => {
      const detailPayload = res.data.response_output.detail
      setDetailRes(detailPayload)
      setIsSuccess(true)
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500 || status === 404) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setErrors({email: "We don't recognize that email address. Please try again."})
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='forgot-password-page' className='w-full'>
      {isSuccess ? (
        <div className=''>
          <div className='mb-3 font-bold text-fs-5 text-neutral-900'>Email Sent</div>
          <div className='mb-14 text-neutral-600'>
            We sent an email to{' '}
            <span className='font-medium text-neutral-900'>{detailRes?.email}</span> with a link to
            reset your password.
          </div>

          <div className='text-neutral-600'>
            Didn’t receive an email?{' '}
            <span
              onClick={() => submitFn.mutate({email: detailRes?.email as string})}
              className='ml-3 font-medium cursor-pointer text-primary-500'
            >
              Resend
            </span>
          </div>
        </div>
      ) : (
        <>
          <div className='mb-14'>
            <div className='mb-3 font-bold text-fs-5 text-neutral-900'>Reset your Password</div>
            <div className='text-neutral-600'>
              Enter the email address associated with your account to receive a link to reset your
              password.
            </div>
          </div>

          {formik.status && <GAlert className='mb-8'>{formik.status}</GAlert>}

          <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
            <div className='mb-9'>
              <FormLabel className='mb-2'>Email</FormLabel>
              <FormText
                {...formik.getFieldProps('email')}
                name='email'
                type='email'
                placeholder='Enter email address'
                maxLength={50}
                error={formik.errors.email}
                touched={formik.touched.email}
              />
            </div>

            <div className='flex items-center justify-between gap-4'>
              <Link
                to={AuthScreens.LOGIN_EMAIL.PATH}
                className='bg-white border border-primary-500 text-primary-500 font-medium flex-1 py-[10px] px-[20px] rounded-lg hover:bg-primary-light-2 transition text-center'
              >
                Back to Login
              </Link>

              <GButton
                type='submit'
                size='large'
                className='flex-1'
                loading={submitFn.isLoading}
                disabled={submitFn.isLoading || !formik.dirty}
              >
                Continue
              </GButton>
            </div>
          </form>
        </>
      )}
    </div>
  )
}

export default ForgotPassword
