import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from 'src/setup'
import MasterLayout from '../components/Layout/MasterLayout'
import AuthRoutes from '../modules/auth/AuthRoutes'
import {Logout} from '../modules/auth/page/Logout/Logout'
import AuthRedux from '../modules/auth/redux/AuthRedux'
import Error404 from '../modules/error/error404'
import {toAbsoluteUrl} from '../utils/asset-helpers-utils'
import {PrivateRoutes} from './PrivateRoutes'

const Routes: FC = () => {
  const accessToken: any = useSelector<RootState>(
    ({auth}) => Boolean(auth.accessToken),
    shallowEqual
  )

  const loadingRedux = useSelector<RootState>(
    ({auth}) => auth?.fetchingUser,
    shallowEqual
  ) as AuthRedux.FetchingUserState

  return (
    <Switch>
      {!accessToken ? (
        <Route path='/auth'>
          <AuthRoutes />
        </Route>
      ) : (
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/logout' component={Logout} />

      {!accessToken ? (
        <Redirect to='/auth/login-email' />
      ) : loadingRedux === 'loading' ? (
        <Route path='*'>
          <div id='splash-screen' className='splash-screen'>
            <img src={toAbsoluteUrl('/logo_ait.svg')} alt='FSM logo' style={{height: '5em'}} />
            <span>Loading ...</span>
          </div>
        </Route>
      ) : (
        <MasterLayout>
          <PrivateRoutes />
          <Route path='/error/404' component={Error404} />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
