import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {FormLabel, FormPassword, FormText, GAlert, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers-utils'
import {cn} from 'src/app/utils/cn-utils'
import {emailRegExp} from 'src/app/utils/input-utils'
import * as Yup from 'yup'
import AuthScreens from '../../Screens'
import AuthRedux from '../../redux/AuthRedux'
import {loginByEmail} from '../../services/Auth.services'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Field is required')
    .matches(emailRegExp, 'Please enter a valid email address'),
  password: Yup.string().required('Field is required'),
})

const initialValues = {
  email: '',
  password: '',
}

const LoginEmail: FC = () => {
  const dispatch = useDispatch()
  useWebTitle('Login')

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      return loginByEmail(payload)
    },
    onSuccess: (result) => {
      const data = result.data.response_output.detail
      const {token, refreshtoken} = data

      dispatch(AuthRedux.actions.login(token, refreshtoken))
    },
    onError: (e: any) => {
      const status = e.response.status

      if (status >= 500 || status === 404) {
        formik.setStatus('Service Temporarily Unavailable. Please try again later.')
      } else {
        formik.setStatus('Login failed! Please recheck the username and password and try again')
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => submitFn.mutate(values),
  })

  return (
    <div data-testid='login-email-page' className='w-full'>
      <img src={toAbsoluteUrl('/logo_ait.svg')} alt='AIT' className='h-[62px] mb-11' />

      <div className='mb-12'>
        <div className='mb-2 font-semibold text-fs-5 text-neutral-900'>Welcome</div>
        <div className='text-neutral-600'>Please login to your account</div>
      </div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-7'>
          <FormLabel className='mb-2'>Email</FormLabel>
          <FormText
            {...formik.getFieldProps('email')}
            name='email'
            type='email'
            placeholder='Enter email address'
            maxLength={50}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </div>

        <div className={cn(formik.status ? 'mb-5' : 'mb-[52px]')}>
          <FormLabel className='mb-2'>Password</FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='Enter Password'
            minLength={8}
            error={formik.errors.password}
            touched={formik.touched.password}
          />

          <div className='pb-2 mt-4 font-medium text-end text-primary-500'>
            <Link to={AuthScreens.FORGOT.PATH}>
              <span className='cursor-pointer'>Forgot Password</span>
            </Link>
          </div>
        </div>

        {formik.status && (
          <GAlert
            className='mb-[52px] flex items-center'
            icon='IconWarning2'
            iconClassname='w-6 h-6'
          >
            {formik.status}
          </GAlert>
        )}

        <GButton
          type='submit'
          size='large'
          className='w-full'
          loading={submitFn.isLoading}
          disabled={submitFn.isLoading || !formik.dirty}
        >
          Login
        </GButton>
      </form>
    </div>
  )
}

export default LoginEmail
