import {Order} from 'src/app/models/api.types'

export interface IGlobal {
  created_at?: string
  updated_at?: string
  deleted_at?: string
  id?: string
  slug?: string
  code?: string
  order?: number
  value?: string
  value_ext?: string
}

export interface IGlobalQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
}

export enum EGlobalSlug {
  GENDER = 'gender',
  PRIORITY = 'priority',
  REGEX = 'regex',
  AGENT_ROLE = 'agent_role',
  STATUS = 'status',
  REMINDER = 'reminder',
  RECURRING_FREQUENCY = 'recurring_frequency',
}

export type TGlobalSlug = keyof typeof EGlobalSlug
