import {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../components/Layout/FallbackView'
import AdministrationScreens from '../modules/administration/AdministrationScreen'
import AgentManagementScreens from '../modules/agent-management/AgentManagementScreen'
import DashboardScreens from '../modules/dashboard/Screens'
import FormManagementScreens from '../modules/form-management/FormManagementScreen'
import MasterScreens from '../modules/master/MasterScreen'
import ProfileScreens from '../modules/profile/Screens'
import TaskManagementScreens from '../modules/task-management/TaskManagementScreen'
// import WoRequestScreens from '../modules/wo-request/WoRequestScreen'

export function PrivateRoutes() {
  const DashboardPage = lazy(() => import('../modules/dashboard/DashboardRoutes'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfileRoutes'))
  const TaskManagementPage = lazy(() => import('../modules/task-management/TaskManagementRoutes'))
  // const WoRequestPage = lazy(() => import('../modules/wo-request/WoRequestRoutes'))
  const FormManagementPage = lazy(() => import('../modules/form-management/FormManagementRoutes'))
  const AgentManagementPage = lazy(
    () => import('../modules/agent-management/AgentManagementRoutes')
  )
  const MasterPage = lazy(() => import('../modules/master/MasterRoutes'))
  const AdministrationPage = lazy(() => import('../modules/administration/AdministrationRoutes'))

  return (
    <>
      <Suspense fallback={<FallbackView />}>
        <Switch>
          <Route path={DashboardScreens.DASHBOARD.PATH} component={DashboardPage} />
          <Route path={ProfileScreens.PROFILE.PATH} component={ProfilePage} />
          <Route path={TaskManagementScreens.TASK_MANAGEMENT.PATH} component={TaskManagementPage} />
          {/* <Route path={WoRequestScreens.WO_REQUEST.PATH} component={WoRequestPage} /> */}
          <Route path={FormManagementScreens.FORM_MANAGEMENT.PATH} component={FormManagementPage} />
          <Route
            path={AgentManagementScreens.AGENT_MANAGEMENT.PATH}
            component={AgentManagementPage}
          />
          <Route path={MasterScreens.MASTER.PATH} component={MasterPage} />
          <Route path={AdministrationScreens.ADMINISTRATION.PATH} component={AdministrationPage} />

          <Redirect from='/auth' to='/dashboard' />
          <Redirect exact from='/' to='/dashboard' />
          <Redirect to='/error/404' />
        </Switch>
      </Suspense>
    </>
  )
}
