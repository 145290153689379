import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import GlobalParamRedux from 'src/app/modules/global/redux/GlobalParamRedux'
import LocalizationRedux from 'src/app/plugins/i18n/localization-redux'

export const rootReducer = () =>
  combineReducers({
    auth: AuthRedux.reducer(),
    localization: LocalizationRedux.reducer(),
    global: GlobalParamRedux.reducer(),
  })

export type RootState = ReturnType<ReturnType<typeof rootReducer>>

export function* rootSaga() {
  yield all([AuthRedux.saga()])
}
