import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models/api.types'
import {IProfile} from '../models/Profile.model'

export const PROFILE = `/api/v1/admins/users/profile`
export const USER = '/api/v1/admins/users'

export const updateProfile = (data?: any) => {
  return axios.put(PROFILE, data)
}

export const getProfile = () => {
  return axios.get<BaseResponse<Response<IProfile>>>(`${USER}/profile`)
}
